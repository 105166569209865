var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.officeRecords.length !== 0
        ? _c("ReviewContractTable", {
            attrs: {
              title: _vm.$t(
                "tableItems.reviewContract.stepFourthContent.title1"
              ),
              "content-page-name": _vm.contentPageName,
              explanation: _vm.$t(
                "tableItems.reviewContract.stepFourthContent.explanation"
              ),
              records: _vm.officeRecords
            }
          })
        : _vm._e(),
      _vm.codeValueExampleRecord.codeValueExample
        ? _c("v-container", { staticClass: "my-0 mx-auto pa-1" }, [
            _c(
              "p",
              {
                staticClass: "ma-0",
                staticStyle: { "word-wrap": "break-word", "font-size": "14px" }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.codeValueExampleRecord.codeValueExample) +
                    "\n    "
                )
              ]
            )
          ])
        : _vm._e(),
      _c("SecuritiesAndTermsConfirmTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepFourthContent.title2"),
          "content-page-name": _vm.contentPageName,
          explanation: _vm.$t(
            "tableItems.reviewContract.stepFourthContent.explanation"
          ),
          record: _vm.securitiesAndTermsConfirmRecord
        }
      }),
      _vm.securitiesDeliveryAddressRecord.securitiesDeliveryAddress
        ? _c("ReviewContractTable", {
            attrs: {
              title: _vm.$t(
                "tableItems.reviewContract.stepFourthContent.title3"
              ),
              "content-page-name": _vm.contentPageName,
              explanation: _vm.$t(
                "tableItems.reviewContract.stepFourthContent.explanation"
              ),
              record: {}
            }
          })
        : _vm._e(),
      _vm.securitiesDeliveryAddressRecord.securitiesDeliveryAddress
        ? _c(
            "v-container",
            {
              staticClass: "overflow-y-auto",
              staticStyle: {
                height: "100px",
                "max-width": "100%",
                border: "#cccccc 1px solid"
              },
              attrs: { wrap: "" }
            },
            [
              _c(
                "div",
                { staticClass: "my-0 mx-auto" },
                _vm._l(
                  _vm.splitMessageLine(
                    _vm.securitiesDeliveryAddressRecord
                      .securitiesDeliveryAddress
                  ),
                  function(message, index) {
                    return _c(
                      "p",
                      {
                        key: index,
                        staticClass: "ma-0",
                        staticStyle: { "word-wrap": "break-word" }
                      },
                      [_vm._v("\n        " + _vm._s(message) + "\n      ")]
                    )
                  }
                ),
                0
              )
            ]
          )
        : _vm._e(),
      _vm.secondContactRegistrationRecords.length !== 0
        ? _c("ReviewContractTable", {
            attrs: {
              title: _vm.$t(
                "tableItems.reviewContract.stepFourthContent.title4"
              ),
              "content-page-name": _vm.contentPageName,
              explanation: _vm.$t(
                "tableItems.reviewContract.stepFourthContent.explanation"
              ),
              records: _vm.secondContactRegistrationRecords
            }
          })
        : _vm._e(),
      _c("ReviewContractTable", {
        attrs: {
          title: _vm.$t("tableItems.reviewContract.stepFourthContent.title5"),
          "content-page-name": _vm.contentPageName,
          record: {}
        }
      }),
      _c(
        "v-container",
        {
          staticClass: "overflow-y-auto",
          staticStyle: {
            height: "100px",
            "max-width": "100%",
            border: "#cccccc 1px solid"
          },
          attrs: { wrap: "" }
        },
        [
          _c("div", { staticClass: "my-0 mx-auto" }, [
            _c(
              "p",
              {
                staticClass: "ma-0",
                staticStyle: { "word-wrap": "break-word" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.contractAgencyStaffRecord.staffName) +
                    "\n      "
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }