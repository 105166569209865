<template>
  <v-container>
    <!-- 団体扱・集団扱等の情報 -->
    <ReviewContractTable
      v-if="officeRecords.length !== 0"
      :title="$t('tableItems.reviewContract.stepFourthContent.title1')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepFourthContent.explanation')
      "
      :records="officeRecords"
    />
    <v-container
      v-if="codeValueExampleRecord.codeValueExample"
      class="my-0 mx-auto pa-1"
    >
      <p
        class="ma-0"
        style="word-wrap: break-word; font-size: 14px;"
      >
        {{ codeValueExampleRecord.codeValueExample }}
      </p>
    </v-container>

    <!-- 証券・約款の確認方法 -->
    <SecuritiesAndTermsConfirmTable
      :title="$t('tableItems.reviewContract.stepFourthContent.title2')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepFourthContent.explanation')
      "
      :record="securitiesAndTermsConfirmRecord"
    />

    <!-- 証券等送付先のご確認 -->
    <ReviewContractTable
      v-if="securitiesDeliveryAddressRecord.securitiesDeliveryAddress"
      :title="$t('tableItems.reviewContract.stepFourthContent.title3')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepFourthContent.explanation')
      "
      :record="{}"
    />
    <v-container
      v-if="securitiesDeliveryAddressRecord.securitiesDeliveryAddress"
      class="overflow-y-auto"
      style="height: 100px; max-width:100%; border: #cccccc 1px solid;"
      wrap
    >
      <div class="my-0 mx-auto">
        <p
          v-for="(message, index) in splitMessageLine(securitiesDeliveryAddressRecord.securitiesDeliveryAddress)"
          :key="index"
          class="ma-0"
          style="word-wrap: break-word;"
        >
          {{ message }}
        </p>
      </div>
    </v-container>

    <!-- 第2連絡先の登録のご確認 -->
    <ReviewContractTable
      v-if="secondContactRegistrationRecords.length !== 0"
      :title="$t('tableItems.reviewContract.stepFourthContent.title4')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepFourthContent.explanation')
      "
      :records="secondContactRegistrationRecords"
    />

    <!-- 取扱者 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepFourthContent.title5')"
      :content-page-name="contentPageName"
      :record="{}"
    />
    <v-container
      class="overflow-y-auto"
      style="height: 100px; max-width:100%; border: #cccccc 1px solid;"
      wrap
    >
      <div class="my-0 mx-auto">
        <p
          class="ma-0"
          style="word-wrap: break-word;"
        >
          {{ contractAgencyStaffRecord.staffName }}
        </p>
      </div>
    </v-container>
  </v-container>
</template>
<script>
import ReviewContractTable from '@/pages/contractor/reviewContractTemplates/ReviewContractTable.vue';
import SecuritiesAndTermsConfirmTable from '@/pages/contractor/reviewContractTemplates/SecuritiesAndTermsConfirmTable.vue';
import {
  officeData,
  codeValueExampleData,
  securitiesAndTermsConfirmData,
  securitiesDeliveryAddressData,
  secondContactRegistrationData,
  contractAgencyStaffData,
} from '@/lib/reviewContractData/stepFourthContentData';
import { paymentGroupMethodCode } from '@/lib/const';
import { getOffice } from '@/apis/contractor/offices';
import { getContract } from '@/apis/contractor/contracts';
import { getAgentUser } from '@/apis/contractor/agentUsers';
import { getContractInformation } from '@/apis/contractor/contractInformations';
import { convertEraDate, postalCodeAndFullAddress, splitMessageLine } from "@/lib/commonUtil";
export default {
  components: {
    SecuritiesAndTermsConfirmTable,
    ReviewContractTable,
  },
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    contentPageName: 'stepFourthContent',
    officeRecords: officeData,
    codeValueExampleRecord: codeValueExampleData,
    securitiesAndTermsConfirmRecord: securitiesAndTermsConfirmData,
    securitiesDeliveryAddressRecord: securitiesDeliveryAddressData,
    secondContactRegistrationRecords: secondContactRegistrationData,
    contractAgencyStaffRecord: contractAgencyStaffData,
  }),

  async mounted() {
    // 団体扱・集団扱等の情報を取得
    const office = await getOffice().catch(() => {});
    // 第2の連絡先登録情報を取得
    const agentUser = await getAgentUser().catch(() => {});
    // Web証券・Web約款の選択取得
    const contractInformation = await getContractInformation().catch(() => {});

    // 団体扱・集団扱等の情報
    this.setOfficeRecords(this.contract, office);

    // 証券・約款の確認方法
    // 書面の送付先(契約者住所へ送付/送付先住所へ送付)
    this.setSecuritiesAndTermsConfirmRecord(this.contract, contractInformation);

    // 第2連絡先の登録の情報
    this.setSecondContactRegistrationRecords(agentUser);

    // 取扱者
    this.$set(
        this.contractAgencyStaffRecord,
        'staffName',
        this.contract.contractAgency.staffName
    );
  },
  methods: {
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    setOfficeRecords: function(contract, office) {
      let officeRecordsNullIndexArray = [];
      const handlingTypeDebtor = office.paymentGroupMethodCode === paymentGroupMethodCode['債務者集団扱'];
      const handlingTypeGroup = office.paymentGroupMethodCode === paymentGroupMethodCode['集団扱/団体扱'];

      // 団体扱・集団扱等の情報
      this.officeRecords.forEach((object, index) => {
        // 申込人との関係(団体扱/集団扱)
        this.setStepFourthContentData(
          object.key,
          'relationshipName',
          office.relationshipName,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 勤務先名/直接所属する集団名タイトルの判定
        let keyName;
        if (handlingTypeDebtor) {
          keyName =  'paymentGroupMethodName1'; // 債務者集団扱の時
          this.setStepFourthContentData(
            object.key,
            'paymentGroupMethodName2',
            null,
            this.officeRecords,
            officeRecordsNullIndexArray,
            index
          );
        } else if (handlingTypeGroup) {
          keyName =  'paymentGroupMethodName2'; // 集団扱/団体扱の時
          this.setStepFourthContentData(
            object.key,
            'paymentGroupMethodName1',
            null,
            this.officeRecords,
            officeRecordsNullIndexArray,
            index
          );
        } else {
          this.setStepFourthContentData(
            object.key,
            'paymentGroupMethodName1',
            null,
            this.officeRecords,
            officeRecordsNullIndexArray,
            index
          );
          this.setStepFourthContentData(
            object.key,
            'paymentGroupMethodName2',
            null,
            this.officeRecords,
            officeRecordsNullIndexArray,
            index
          );
        }

        // 勤務先名/直接所属する集団名
        this.setStepFourthContentData(
          object.key,
          keyName,
          office.employmentName,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 契約者と被保険者との続柄※
        this.setStepFourthContentData(
          object.key,
          'insuranceUserRelationshipName',
          contract.contractUser.insuranceUserRelationshipName,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 控除(集金)開始年月
        this.setStepFourthContentData(
          object.key,
          'deductionStartDateText',
          office.deductionStartDateText,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 現職/退職者区分
        this.setStepFourthContentData(
          object.key,
          'classificationName',
          office.classificationName,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 集金者集金方法
        this.setStepFourthContentData(
          object.key,
          'collectMoneyName',
          office.collectMoneyName,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 登録口座 (団体扱/集団扱の場合表示)
        this.setStepFourthContentData(
          object.key,
          'groupFirstRegisteredBankAccount',
          this.setHandlingTypeValue(handlingTypeGroup, office.firstRegisteredBankAccount),
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 基準年月
        this.setStepFourthContentData(
          object.key,
          'referenceDateText',
          office.referenceDateText,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 退職年月
        this.setStepFourthContentData(
          object.key,
          'retirementDateText',
          office.retirementDateText,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 第1回保険料
        this.setStepFourthContentData(
          object.key,
          'firstInsurancePrice',
          office.firstInsurancePrice,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 登録口座① (債務者集団扱の場合表示)
        this.setStepFourthContentData(
          object.key,
          'firstRegisteredBankAccount',
          this.setHandlingTypeValue(handlingTypeDebtor, office.firstRegisteredBankAccount),
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 作成方法 (債務者集団扱の場合)
        this.setStepFourthContentData(
          object.key,
          'createMethod',
          this.setHandlingTypeValue(handlingTypeDebtor, office.createMethod),
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // お知らせ方法 (債務者集団扱の場合表示)
        this.setStepFourthContentData(
          object.key,
          'notificationMethod',
          this.setHandlingTypeValue(handlingTypeDebtor, office.notificationMethod),
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // お払込み先コンビニ (債務者集団扱の場合表示)
        this.setStepFourthContentData(
          object.key,
          'paymentConvenienceStore',
          this.setHandlingTypeValue(handlingTypeDebtor, office.paymentConvenienceStore),
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 第2回目以降保険料
        this.setStepFourthContentData(
          object.key,
          'insurancePrice',
          office.insurancePrice,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 登録口座② (債務者集団扱の場合表示)
        this.setStepFourthContentData(
          object.key,
          'secondRegisteredBankAccount',
          this.setHandlingTypeValue(handlingTypeDebtor, office.secondRegisteredBankAccount),
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 融資満了日
        this.setStepFourthContentData(
          object.key,
          'loanEndDateText',
          office.loanEndDateText,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );

        // 集団区分
        this.setStepFourthContentData(
          object.key,
          'creditorCode',
          office.creditorCode,
          this.officeRecords,
          officeRecordsNullIndexArray,
          index
        );
      });

      // NULLで連携された項目を画面非表示とする。
      for (let i = officeRecordsNullIndexArray.length; i >= 0; i--) {
        this.$delete(this.officeRecords, officeRecordsNullIndexArray[i]);
      }
      
      // コード値の凡例
      this.$set(
          this.codeValueExampleRecord,
          'codeValueExample',
          contract.contractUser.codeValueExample
      );
    },

    setSecuritiesAndTermsConfirmRecord: function(
      contract,
      contractInformation
    ) {
      // 証券・約款の確認方法
      // 証券(web)
      this.$set(
        this.securitiesAndTermsConfirmRecord,
        'webSecurity',
        this.convertFromBooleanToCheckString(contractInformation.isWebSecured)
      );

      // 証券(書面)
      this.$set(
        this.securitiesAndTermsConfirmRecord,
        'documentSecurity',
        this.convertFromBooleanToCheckString(contractInformation.isDocumentSecured)
      );

      // 約款(web)
      this.$set(
        this.securitiesAndTermsConfirmRecord,
        'webAgreement',
        this.convertFromBooleanToCheckString(contractInformation.isWebAgreed)
      );

      // 約款(書面)
      this.$set(
        this.securitiesAndTermsConfirmRecord,
        'documentAgreement',
        this.convertFromBooleanToCheckString(contractInformation.isDocumentAgreed)
      );

      // 次回満期時のご案内(web)
      this.$set(
        this.securitiesAndTermsConfirmRecord,
        'webExpireGuidance',
        this.convertFromBooleanToCheckString(contractInformation.isWebExpireGuided)
      );

      // 次回満期時のご案内(書面)
      this.$set(
        this.securitiesAndTermsConfirmRecord,
        'documentExpireGuidance',
        this.convertFromBooleanToCheckString(contractInformation.isDocumentExpireGuided)
      );

      // Ｗｅｂ確認文言
      this.$set(
          this.securitiesAndTermsConfirmRecord,
          'confirmationText',
          contractInformation.confirmationText !== null ? contractInformation.confirmationText : this.$t('header.reviewContractTable.stepFourthContent.noText')
      );

      // 書面の送付先(契約者住所へ送付/送付先住所へ送付)
      if (
        contractInformation.documentSenderType ===
        this.$t(
          'header.reviewContractTable.stepFourthContent.contractUserAddress'
        )
      ) {
        this.$set(
          this.securitiesAndTermsConfirmRecord,
          'contractUserAddress',
          this.$t('header.reviewContractTable.stepFourthContent.hasCheck')
        );
        this.$set(
          this.securitiesAndTermsConfirmRecord,
          'addressDesignat',
          this.$t('header.reviewContractTable.stepFourthContent.noCheck')
        );
      } else if (
        contractInformation.documentSenderType ===
        this.$t('header.reviewContractTable.stepFourthContent.addressDesignat')
      ) {
        this.$set(
          this.securitiesAndTermsConfirmRecord,
          'contractUserAddress',
          this.$t('header.reviewContractTable.stepFourthContent.noCheck')
        );
        this.$set(
          this.securitiesAndTermsConfirmRecord,
          'addressDesignat',
          this.$t('header.reviewContractTable.stepFourthContent.hasCheck')
        );
      } else if (
        contractInformation.documentSenderType ===
        this.$t('header.reviewContractTable.stepFourthContent.documentSenderTypeNone')
      ) {
        this.$set(
          this.securitiesAndTermsConfirmRecord,
          'contractUserAddress',
          this.$t('header.reviewContractTable.stepFourthContent.noCheck')
        );
        this.$set(
          this.securitiesAndTermsConfirmRecord,
          'addressDesignat',
          this.$t('header.reviewContractTable.stepFourthContent.noCheck')
        );
      }
      this.$set(
          this.securitiesDeliveryAddressRecord,
          'securitiesDeliveryAddress',
          this.postalCodeAndFullAddress(contract.contractUser.sendingPostalCode, contract.contractUser.sendingFullAddress)
      );
    },

    setSecondContactRegistrationRecords: function(agentUser) {
      let scRecordsNullIndexArray = [];
      // 第2連絡先の登録の情報
      this.secondContactRegistrationRecords.forEach((object, index) => {
        // 第2連絡先の登録
        this.setStepFourthContentData(
          object.key,
          'addressRegisteredText',
          agentUser.addressRegisteredText,
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );

        // 氏名(漢字)
        this.setStepFourthContentData(
          object.key,
          'secondFullNameKanji',
          agentUser.fullName,
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );

        // 氏名(カナ)
        this.setStepFourthContentData(
          object.key,
          'secondFullNameKana',
          agentUser.fullNameKana,
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );

        // 住所(漢字)
        this.setStepFourthContentData(
          object.key,
          'secondAddressKanji',
          this.postalCodeAndFullAddress(agentUser.postalCode, agentUser.fullAddress),
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );

        // 生年月日
        this.setStepFourthContentData(
          object.key,
          'secondDateOfBirth',
          convertEraDate(agentUser.birthday),
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );

        // 性別
        this.setStepFourthContentData(
          object.key,
          'secondGender',
          agentUser.sex,
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );

        // 電話番号1
        this.setStepFourthContentData(
          object.key,
          'secondPhoneNumber1',
          this.fullPhoneNumber(agentUser.firstPhoneNumberType, agentUser.firstPhoneNumber),
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );

        // 電話番号2
        this.setStepFourthContentData(
          object.key,
          'secondPhoneNumber2',
            this.fullPhoneNumber(agentUser.secondPhoneNumberType, agentUser.secondPhoneNumber),
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );

        // 契約者との関係
        this.setStepFourthContentData(
          object.key,
          'relationshipWithContractor',
          agentUser.contractUserRelationshipName,
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray,
          index
        );
      });

      // NULLで連携された項目を画面非表示とする。
      for (let i = scRecordsNullIndexArray.length; i >= 0; i--) {
        this.$delete(
          this.secondContactRegistrationRecords,
          scRecordsNullIndexArray[i]
        );
      }
    },
    setStepFourthContentData: function(
      key,
      keyName,
      data,
      dataObject,
      indexArray,
      index
    ) {
      if (key === keyName) {
        if (data === null) {
          indexArray.push(index);
        }
        if (data === ''){
          this.$set(dataObject[index], 'value', '　'); // 半角の空文字('')の場合、ReviewContractTable.vueのgetColspanでcolspan2が与えられてしまうので、全角の空文字('　')を返す。
        } else {
          this.$set(dataObject[index], 'value', data);
        }
      }
    },

    setHandlingTypeValue: function(handlingType, value) {
      if (!handlingType) {
        return null;
      }
    return value;
    },

    convertFromBooleanToCheckString: function(value) {
      if (value === null) return this.$t('header.reviewContractTable.stepFourthContent.noText')

      return value ? this.$t('header.reviewContractTable.stepFourthContent.hasCheck') : this.$t('header.reviewContractTable.stepFourthContent.noCheck')
    },
    postalCodeAndFullAddress(postalCode, fullAddress) {
      return postalCodeAndFullAddress(postalCode, fullAddress)
    },

    // 第２連絡先のご登録（電話番号1,2）
    fullPhoneNumber: function(phoneNumberType, phoneNumber) {
      if(phoneNumberType && phoneNumber) return `${phoneNumberType}　${phoneNumber}`;

      return phoneNumberType || phoneNumber;
    },
  },
};
</script>
